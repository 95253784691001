import React from "react";
import ReactDOM from "react-dom";
import Login from "./components/LoginContainer";
import store from "./store";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root"));

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://7bb3649351094b5ea9c7afa7957765b3@o430466.ingest.sentry.io/5379066",
		release: process.env.npm_version,
	});
}

root.render(
	<Provider store={store}>
		<Login />
	</Provider>
);
